<template>
  <div class="main-page trace-data-detail">
    <custom-header customBack @onBack="onBack"></custom-header>
    <div class="desc">
      <h4>{{item.appname}}</h4>
    </div>
    <div class="inner">
      <div class="intro">
        <div class="main-font">
          <div class="box" :style="{'background-image': `url(${item.appimg})`}" v-if="data.type == 'app'"></div>
          <div class="box" v-else>
            <div class="font">{{item.appname}}</div>
          </div>
          <ul>
            <li v-if="data.type == 'app'">商业换脸软件：<span>{{item.appname}}</span></li>
            <li v-else>伪造算法：<span>{{item.appname}}</span></li>
            <li>所属篡改类型：<span>{{item.type}}</span></li>
          </ul>
        </div>
        <div class="desc" v-if="data.type != 'app'">{{item.describe}}</div>
      </div>
      <ul class="img-list" :style="{height: imgHeight + 'px'}">
        <li class="item" v-for="(item, index) in item.imagelist" :key="index">
          <div class="box">
            <img :src="item" alt="">
          </div>
          <p>{{item.name}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader.vue";

export default {
  name: "trace-data-detail",
  components: {
    "custom-header": CustomHeader,
  },
  data(){
    return {
      item: {},
      data: {},
      imgHeight: 0,
    };
  },
  mounted() {
    this.data = JSON.parse(localStorage.getItem('rj_trace_detail_data') || '{}');
    let {item, type} = this.data;
    if (!item) {
      this.$router.go(-1);
    }

    if (type == 'app') {
      item.appname =(item.desc || '').split('商业换脸软件:')[1];
    }
    item.type =(item.desc1 || '').split('所属大类：')[1];
    this.item = item;

    setTimeout(() => {
      let fontSize = Number(document.documentElement.style.fontSize.replace('px', ''));
      this.imgHeight = document.body.clientHeight - document.querySelector('.img-list').offsetTop - 0.444 * fontSize;
    }, 100)

  },
  methods: {
    onBack() {
      let {tab} = this.data;
      localStorage.setItem('rj_tab_key', tab);
      localStorage.removeItem('rj_trace_detail_data');
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less">
@import '../../assets/less/const';
  .trace-data-detail {
    .inner {
      .intro {
        padding: 28px 32px;
        background-image: linear-gradient(180deg, rgba(56,80,159,0.41) 0%, rgba(56,80,159,0.41) 100%);
        font-size: 30px;
        margin-bottom: 32px;
        .main-font {
          display: flex;
          align-items: center;
          .box {
            width: 226px;
            height: 226px;
            margin-right: 44px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            font-size: 30px;
            color: #00F1FF;
            font-weight: bold;
            text-align: center;
            position: relative;
            .gradient-border();
            .font {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              padding: 0 10px;
              width: 100%;
              word-break: break-all;
            }
          }
          > ul {
            > li {
              margin-bottom: 40px;
              &:last-child {
                margin-bottom: 0;
              }
              > span {
                font-size: 36px;
                font-weight: bold;
              }
            }
          }
        }
        .desc {
          padding-top: 20px;
          margin-top: 30px;
          border-top: 1px solid #5D9FFF;
        }
      }
    }
    .img-list {
      margin-left: -16px;
      margin-right: -16px;
      margin-bottom: -32px;
      overflow: auto;
      .item {
        display: inline-block;
        margin: 0 16px 32px 16px;
        text-align: center;
        font-size: 30px;
        color: #00F1FF;
        font-weight: bold;
        .box {
          width: 312px;
          height: 312px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          position: relative;
          background-color: #000;
          > img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
</style>
